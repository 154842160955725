import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showMenu = false;
  taglines = [
    'Money making made easy',
    'Safety of FD, with the returns of equity',
  ]

}
