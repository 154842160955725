  <!-- ======= Mobile nav toggle button ======= -->
<!-- Responsive Navigation Bar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
  <div class="container">
    <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
      <span class="navbar-toggler-icon"></span>
  </button>
    <div class="collapse navbar-collapse text-right" id="navbar">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link active" href="#home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

  <!-- ======= Section: Home ======= -->
  <section id="home" class="d-flex flex-column justify-content-center text-center">
    <div class="container" data-aos="zoom-in" data-aos-delay="100">
      <img src="../assets/img/logo.svg" alt="logo" style="height: 350px; width: 350px;"/>
      <h1>
        Block Gains
        <h2>Consider it done.</h2>
        <p>
          <ngx-typed-js [strings]="taglines" [typeSpeed]="100" [loop]="true" backDelay= "3000" class="subheader" >
            <span class="typing typed"></span>
          </ngx-typed-js>
          </p>
    </h1>
    <!-- <div class="social-links">
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      </div> -->
    </div>
  </section>
  <!-- End Home -->
  <main id="main">
    <!-- ======= Section: About ======= -->
    <section id="about" class="about about-info">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>About</h2>
        </div>
        <div class="container mt-5">
            <h4 class="text-center">ABI ZORO - Your Trusted Partner in Crypto Trading</h4>
        
            <div class="row align-items-center mt-5 p-4" data-aos="zoom-in" data-aos-delay="100">
              <div class="col-lg-4 text-end mb-3 mb-lg-0">
                <img src="../assets/img/about/storytelling.svg" class="img-fluid" alt="" style="max-height: 150px; width: auto;">
              </div>
              <div class="col-lg-8 text-start">
                <h4 class="bold-title">Who We Are</h4>
                <p class="fst-italic">
                  At ABI ZORO, we are driven by a shared vision of empowering individuals and institutions to navigate the exciting and dynamic world of cryptocurrency trading. Our team is composed of experts with years of experience in the crypto space, including traders, analysts, and developers who are committed to helping you achieve your financial goals.
                </p>
              </div>
            </div>
            <br><br><br>
            <div class="row align-items-center mt-5 p-4" data-aos="zoom-in" data-aos-delay="100">
              <div class="col-lg-8 text-end">
                <h4 class="bold-title">What We Offer</h4>
                <p class="fst-italic">
                  The team specializes in cryptocurrency markets, offering insights, analysis, and strategies for informed trading decisions. They utilize blockchain and trading technology for a competitive advantage and prioritize asset safety with strong security measures. Transparency is emphasized through real-time data and clear fee structures. Additionally, they provide educational resources to help users navigate the complex crypto landscape and improve trading skills.
                </p>
              </div>
              <div class="col-lg-4 text-start">
                <img src="../assets/img/about/growth.svg" class="img-fluid" alt="" style="max-height: 150px; width: auto;">
              </div>
            </div>
            <br><br><br>
            <div class="row align-items-center mt-5 p-4" data-aos="zoom-in" data-aos-delay="100">
              <div class="col-lg-4 text-end">
                <img src="../assets/img/about/mission.svg" class="img-fluid" alt="" style="max-height: 150px; width: auto;">
              </div>
              <div class="services col-lg-8 text-start">
                <h4 class="bold-title">Our Mission</h4>
                <p class="fst-italic">
                  Our mission is to make financial consultation accessible, safe, and profitable for everyone. We want to demystify the voodoo of trading, particularly in the crypto space, and thrive in this exciting industry.
                </p>
              </div>
              </div>
        </div>
        

      </div>
    </section>
    <!-- End About -->

    <!-- ======= Section: Contact ======= -->
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact</h2>
          <div class="row align-items-center mt-4">
            <div class="col-12 text-center">
              <p>Join us on a profitable journey to financial independence, explore our platform, and let's navigate the world of crypto together while we write our success stories side by side, supported by our dedicated team.</p>
            </div>
          </div>
        </div>

        <div class="row mt-1">

          <div class="col-lg-4 mt-3">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>ABI ZORO BLOCK GAINS (OPC) PRIVATE LIMITED</p>
                <p>Ram Bagh Balla 630</p>
                <p>Natipora</p>
                <p>Srinagar 190015</p>
                <p>Jammu and Kashmir</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 mt-3">
            <div class="info">
              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>contact@abizoro.com</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 mt-3">
            <div class="info">
              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>9419050357</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact -->

  </main>

  
    <!-- ======= Footer ======= -->
    <footer id="footer">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-4 col-md-6 text-center mb-3">
            <img src="../assets/img/logo.svg" alt="logo" style="height: 150px; width: 150px;" />
          </div>

        </div>
        <div class="row justify-content-center align-items-center">
   
          <div class="col-lg-8 col-md-6 text-center mb-3">
            <h4>ABI ZORO BLOCK GAINS (OPC) PRIVATE LIMITED</h4>
          </div>
        </div>
        <div class="row text-center">
          <span>
            CIN: U70200JK2023OPC015044
          </span>
        </div>
        <div class="credits text-center mt-3">
          Designed by <a href="https://nawabhussain.github.io/">Nawab</a>
        </div>
      </div>
    </footer>
    <!-- End Footer -->